import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTopBanners } from "repositories/hooks";
import { Image } from "shared/components/atoms/Image";
import { TopBanner } from "shared/models/index";
import { Colors } from "shared/styles";
import { styled } from "twin.macro";

const Indicator = styled.div<{ active: boolean }>`
  background: ${(props) => (props.active ? Colors.gradient["01"] : "#F0F3F6")};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: 8px;
  display: inline-block;

  &:first-child {
    margin-left: 0;
  }
`;

export const BannerList = () => {
  const { topBanners } = useTopBanners();
  const handleClickTopBanner = (index: number) => open(topBanners[index]?.url);
  const renderTopBanner = (topBanner: TopBanner) => (
    <Image
      key={topBanner.url}
      src={topBanner.imageUrl}
      width={376}
      height={210}
      layout="responsive"
      priority
    />
  );

  return (
    <Carousel
      autoFocus
      autoPlay
      emulateTouch
      infiniteLoop
      onClickItem={handleClickTopBanner}
      renderIndicator={(_, isSelected) => <Indicator active={isSelected} />}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
    >
      {topBanners.map(renderTopBanner)}
    </Carousel>
  );
};
