import { Carousel } from "shared/components/atoms/Carousel";
import { H2 } from "components/atoms/H2";
import { SwipableFortuneTellerList } from "components/molecules/SwipableFortuneTellerList";
import { CategoryAndTagList } from "components/organisms/CategoryAndTagList";
import { useFavorite } from "features/shared/hooks/useFavorite";
import { BannerList } from "features/Home/components/BannerList";
import { DailyHoroscopeLink } from "features/Home/components/DailyHoroscopeLink";
import { DivinationTagCardList } from "features/Home/components/DivinationTagCardList";
import { SceneTagCardList } from "features/Home/components/SceneTagCardList";
import TagMenus from "features/Home/components/TagMenus";
import { TopicList } from "features/Home/components/TopicList";
import useFetchData from "features/Home/hooks/useFetchData";
import { Image } from "shared/components/atoms/Image";
import { Loading } from "shared/components/templates/Loading";
import { TelFortune, Fortune } from "shared/types";
import tw from "twin.macro";
import Link from "next/link";
import { menuHelper } from "features/menu/utils/menuHelper";
import { MenuGallelyCard } from "shared/features/Menu/components/MenuGallelyCard";
import { Paths } from "utils/routes";
import { useCampaignCoupon } from "features/shared/hooks/useCampaignCoupon";
import { FreeFortuneContentList } from "./FreeFortuneContentList";
import { useSession } from "next-auth/client";
import { PinkRectangle } from "shared/components/atoms/PinkRectangle";

const spaceCss = tw`px-5 mt-8`;

export const Home = () => {
  const [session] = useSession();
  const {
    waitingChatFortuneTellers,
    recommendChatFortuneTellers,
    campaignTelFortunes,
    popularTelFortunes,
    waitingTelFortuneTellers,
    isLoadingWaitingChatFortuneTellers,
    isLoadingRecommendChatFortuneTellers,
  } = useFetchData();
  const { campaignCoupons } = useCampaignCoupon();
  const hasCampaignCoupon = !session || campaignCoupons.length > 0;

  const isLoadingFortuneTellers =
    isLoadingWaitingChatFortuneTellers || isLoadingRecommendChatFortuneTellers;

  const { onClickFavorite } = useFavorite();

  const renderMenuCard = (menu: Fortune | TelFortune) => {
    const { menuPagePath } = menuHelper(menu);
    return (
      <Link href={menuPagePath}>
        <a>
          <MenuGallelyCard
            menu={menu}
            onClickFav={() => onClickFavorite(menu)}
            hasFreeUsage={menu.type === "tel" && hasCampaignCoupon}
          />
        </a>
      </Link>
    );
  };

  return (
    <div data-cy="home">
      <BannerList />
      <DailyHoroscopeLink css={spaceCss} />
      <H2
        css={spaceCss}
        readMore
        href={Paths.fortuneTellers.list.chatWaiting()}
      >
        今すぐ占える待機中の占い師
      </H2>
      <SwipableFortuneTellerList fortuneTellers={waitingChatFortuneTellers} />
      <H2
        css={spaceCss}
        readMore
        href={Paths.fortuneTellers.list.chatPopular()}
      >
        おすすめの占い師
      </H2>
      <SwipableFortuneTellerList fortuneTellers={recommendChatFortuneTellers} />
      {hasCampaignCoupon && (
        <>
          <div tw="mx-5 mt-8">
            <p tw="font-semibold text-2xs tracking-[.41px] text-[#FE4FD0]">
              お持ちのクーポン利用で最大2000円分無料
            </p>
            <H2
              readMore
              href={
                session
                  ? `/campaigns/tel_fortune/1`
                  : `/campaigns/tel_fortune/2`
              }
            >
              今だけ0円〜お得な電話占い
            </H2>
          </div>
          <Carousel data={campaignTelFortunes} renderItem={renderMenuCard} />
        </>
      )}
      <DivinationTagCardList tw="mt-8" />
      <SceneTagCardList tw="mt-8" />

      <div tw="px-5">
        <Link href={Paths.fortuneTellers.list.telPopular()}>
          <a tw="mt-8 block">
            <Image
              tw="rounded-[13px]"
              src={`${process.env.NEXT_PUBLIC_CDN_V2}/home/tel_fortune_banner.png`}
              width={346}
              height={138}
              layout="responsive"
            />
          </a>
        </Link>

        <Link href={Paths.tags.fortunes(40)}>
          <a tw="mt-8 block">
            <Image
              tw="rounded-[13px]"
              src={`${process.env.NEXT_PUBLIC_CDN_V2}/home/spiritual.png`}
              width={346}
              height={138}
              layout="responsive"
            />
          </a>
        </Link>
      </div>

      <H2 css={spaceCss}>人気の電話占い</H2>
      <Carousel data={popularTelFortunes} renderItem={renderMenuCard} />

      <PinkRectangle tw="mt-8">
        <H2 tw="px-5" readMore href={Paths.fortuneTellers.list.telWaiting()}>
          今すぐ電話可能な占い師
        </H2>
        <SwipableFortuneTellerList
          fortuneTellers={waitingTelFortuneTellers}
          waitingStatusKey="any"
        />
      </PinkRectangle>

      <TagMenus />
      <H2 css={spaceCss} readMore href={Paths.freeFortuneContents.index()}>
        新着無料占い
      </H2>
      <FreeFortuneContentList tw="px-5 mt-2" />
      <TopicList />
      <CategoryAndTagList tw="mt-8" />
      {isLoadingFortuneTellers && <Loading loading />}
    </div>
  );
};
